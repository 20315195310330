import React from 'react'
import { useKnowledgeGroups } from '../../hooks/useKnowledgeGroups'
import { DiscoverKnowledgeAreasPage } from '@hrk/huw-module-library'
import { useTranslations } from '../../hooks/useTranslations'
import { useLocale } from '../../hooks/useLocale'

export const BasicKnowledgeGroups: React.FC = () => {
  const { pageLocale } = useLocale()
  const knowledgeGroups = useKnowledgeGroups(pageLocale)
  const {
    discoverKnowledgeAreas,
    search: { resultTranslations },
  } = useTranslations()
  // FIXME: Should be editable in the cms like meinBereich
  const wissensbereicheRichText = `<p>Künstliche Intelligenz, Gesundheitsmanagement, Mitarbeiter:innenführung oder Energietechnik: 
  Die Hochschulen bieten Weiterbildungen zu den verschiedensten Wissensbereichen und für alle Branchen, jedes Alter und jede 
  Karrierestufe an. Von A wie Architektur bis Z wie Zeitmanagement haben wir Ihnen zusammengestellt, welche Weiterbildungsangebote 
  es in den mehr als 100 Wissensbereichen gibt. Außerdem erfahren Sie bei den Beschreibungen der einzelnen Wissensbereiche, 
  an wen sich die Weiterbildungen der Branchen richten, welche Voraussetzungen Sie mitbringen müssen und vor allem welche 
  beruflichen Perspektiven Ihnen eine wissenschaftliche Weiterbildung im jeweiligen Wissensbereich eröffnet.</p>
  <p>Jedes Weiterbildungsangebot ist mindestens einem Wissensbereich zugeordnet, womit eine thematische Suche über 
  Wissensbereiche möglich ist. Mit dem erweiterten Suchfilter können Sie ganz einfach Ihre Suche auf die Wissensbereiche 
  eingrenzen, für die Sie sich interessieren. Wenn Sie sich noch nicht ganz sicher sind, können Sie sich auch gerne durch 
  die einzelnen Beschreibungen durchklicken oder Sie machen unseren 
  <a class="hrk-link" href="/weiterbildungs-interessentest" target="_blank" title="wit - Weiterbildungs-Interessentest">Weiterbildungs-Interessentest</a>.</p>`
  return (
    <DiscoverKnowledgeAreasPage
      title={'Übersicht der Wissensbereiche'}
      description={wissensbereicheRichText}
      knowledgeGroups={knowledgeGroups}
      translations={{ ...discoverKnowledgeAreas, ...resultTranslations }}
    />
  )
}
